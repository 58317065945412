import { onCLS, onFCP, onLCP } from 'web-vitals';
import { sendActivityData } from './api';
import { batchSend } from './utils/batchSend';
import { createActivity } from './utils/activity';

const sendData = batchSend(sendActivityData);

/**
 * For now we are only tracking these 3
 *
 * cls - cumulative layout shift
 * fcp - first contentful paint
 * lcp - largest contentful paint
 *
 * See e.g. https://web.dev/articles/vitals for more details on what they represent
 *
 * See https://github.com/GoogleChrome/web-vitals for any other metric we could track out of the box
 *
 * For any other metrics we want to track, we would need to add custom tracking.
 *
 * e.g. see https://developer.mozilla.org/en-US/docs/Web/API/PerformanceObserver
 */

onCLS((metric) => {
    const activity = createActivity('browser.cls', metric.value);
    sendData(activity);
});

onFCP((metric) => {
    const activity = createActivity('browser.fcp', metric.value);
    sendData(activity);
});

onLCP((metric) => {
    const activity = createActivity('browser.lcp', metric.value);
    sendData(activity);
});
