const DEBOUCE_DELAY = 5000;

export const batchSend = <T>(sendFn: (vals: T[]) => void) => {
    let all: T[] = [];
    let timeout: number | null = null;

    const startSendData = () => {
        const data = all.slice();
        all = [];
        if (data.length) {
            sendFn(data);
        }
    };

    window.addEventListener('pagehide', () => {
        startSendData();
    });

    return (val: T) => {
        all.push(val);

        if (timeout) {
            window.clearTimeout(timeout);
        }

        timeout = window.setTimeout(() => {
            startSendData();
        }, DEBOUCE_DELAY);
    };
};
