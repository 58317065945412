import OcApiRequest, { getRequestVerificationToken } from 'src/Api/OcApiRequest';

export type Activity = {
    traceId: string;
    spanId: string;
    parentSpanId: string;
    sourceName: string;

    ellapsedMilliseconds: number;
    viewedOn: Date;

    tenantCode: string;
    micrositeKey: string;
    navigationKey: string;
    navigationName: string;
    eventName: string;

    viewedByContactKey: string;
    pageOrigin: string;
    pagePath: string;
    referrerUrl: string;
    userHostAddress: string;
    userAgent: string;
};

export const sendActivityData = (activities: Activity[]): Promise<unknown> => {
    const body = JSON.stringify({ activities });
    const formToken = getRequestVerificationToken();
    return fetch('/higherlogic/ocapi/oc/Metrics/Telemetry/SendTelemetry', {
        keepalive: true, // need this fetch only feature
        method: 'POST',
        body,
        headers: {
            'content-type': 'application/json',
            RequestVerificationFormToken: formToken
        }
    });
};
